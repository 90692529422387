import styled from "styled-components";

export const List = styled.ul`
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;
  position: absolute;
  transition: all 0.3s linear;
  z-index: 8;
  top: 60px;
  width: 180px;
  border-radius: 15px;
  list-style: none;
  margin: 0px !important;
  padding: 0px !important;

  li {
    padding: 10px 20px 0px;
    cursor: pointer;
    &:first-child {
      color: #fff;
      padding: 20px 0px;
      text-align: center;
      border-radius: 15px 15px 0 0;
      background: rgba(178, 47, 47, 1);
    }

    &:last-child {
      padding-bottom: 15px !important;
    }
  }
`;

