import styled from "styled-components";
import Text from "../Text/index.jsx";

export const NavbarContainer = styled.div`
  background-color: #fff;
  display: block;
  height: 100vh;
  width: 310px;
  overflow: hidden;
  transition: 0.3s;
  display: flex;
  justify-content: center;
  border-right: 1px solid rgba(28, 28, 30, 0.12);
`;

export const StyledNavbar = styled.div`
  transition: all 0.3s ease-in-out;
  transform: ${(props) =>
    props.isMenuOpen ? "translateX(-115px)" : "translateX(0px)"};
  position: fixed;
  /* margin-top: ${(props) => (props.showDownHeader ? "180px" : "104px")}; */
  /* overflow: clip; */
  z-index: 999;
  /* height: calc(100vh - 80px); */
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30%;
  &:-webkit-scrollbar-thumb {
    background-color: rgba(126, 55, 216, 0.15);
  }
  &::-webkit-scrollbar {
    width: 0px;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #e8ebf2;
  }
`;

export const LeftSideHeader = styled.div`
  display: flex;
  align-items: center;
  z-index: 999;
  height: 44px;
  margin: 4px 20px;
  position: relative;
  justify-content: center;
  height: 128px;
  /* @media (max-width: 700px) {
    display: none;
  } */
`;

export const Logo = styled.img`
  height: 35px;
  width: 220px;
  transform: translate(-16px, 50%);
  position: absolute;
  @media (max-width: 991px) {
    width: 140px;
    padding-left: 5px;
    left: 40px;
  }
`;

export const Bee = styled.img`
  position: absolute;
  height: 50px;
  top: 7px;
    left: 92px;
  @media (max-width: 991px) {
    left: 260px;
  }
`;

export const Navbar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content:center;
  width: 100%;
  text-align: left;
  align-self: flex-start;
  align-items: flex-start;
`;

export const NavMenu = styled.div`
  justify-content: center;
  width: 80%;
  display: flex;
  flex-direction: column;
  margin: 0px 20px;
  align-self: center;
`;

export const Menu = styled.div`
  display: flex;
  gap: 20px;
  margin: 0px auto;
  width: 80%;
  background-color: ${(props) =>
    props.selected ? "rgba(255, 245, 245, 1)" : "#fff"};
  padding: 20px 0px 20px 10px;
  justify-content: flex-start;
  text-align: left;
  align-self: flex-start;
  align-items: flex-start;
  cursor: pointer;
  border-radius: 10px;
`;

export const MenuTitle = styled(Text)`
  color: ${(props) => (props.selected ? "#B22F2F" : "rgba(28, 28, 30, 0.72)")};
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 155.556% */
  letter-spacing: 0.36px;
  text-transform: capitalize;
`;

export const SubMenu = styled.div`
  margin-left: 76px;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  justify-content: flex-start;
  text-align: left;
  align-items: flex-start;
`;

export const SubMenuItem = styled(MenuTitle)`
  font-weight: 400;
  padding: 4px 0px;
  cursor: pointer;
  text-align: left;
`;
