import { useState } from "react";
import IAMContainer from "../../components/IAM/IAMContainer";
import IAMFormContainner from "../../components/IAM/IAMFormContainer";
import IAMImageContainer from "../../components/IAM/IAMImageContainer";
import ForgotPasswordImg from "../../assets/signin.png";
import InputField from "../../components/Input";
import Form from "../../components/Form";
import Text from "../../components/Text";
import FormHeading from "../../components/FormHeading";
import Button from "../../components/Button";
import IAMLogo from "../../components/IAM/IAMLogo";
import axios from "axios";
import { IAM_BASE_URL } from "../../config/api";
import { Navigate, useNavigate } from "react-router-dom";
import EmailIcon from "../../assets/email.png";
import Error from "../../components/Error";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [isDisabled, setDisable] = useState(true);

  const onSubmit = async () => {
    setLoading(true);
    await axios
      .post(`${IAM_BASE_URL}/forgot-password`, {
        email,
      })
      .then((resp) => {
        setLoading(false);
        if (resp?.data?.success) {
          setDisable(true);
          navigate("/update-password");
        }
      })
      .catch((err) => {
        setLoading(false);
        setError(err?.response?.data?.message);
        console.log("error", err);
      });
  };

  if (localStorage.getItem("accessToken")) return <Navigate to="/" replace />;

  return (
    <IAMContainer statement={"Let’s get you back on the system"}>
      <IAMImageContainer src={ForgotPasswordImg} />
      <IAMFormContainner>
        <IAMLogo />
        <div style={{ flex: 1, margin: "auto" }}>
          <FormHeading>Forgot Password</FormHeading>
          <Text style={{ width: 290, marginBottom: 30 }} alignment={"left"}>
            Please provide the email address you used at sign up and we’ll send
            you code to reset your password.
          </Text>
          <Form>
            <Error> {error}</Error>
            <InputField
              id={"email"}
              name={"email"}
              placeholder={"email address"}
              type={"email"}
              icon={EmailIcon}
              validationMessage={"Please enter a valid email"}
              value={email}
              onChange={({ target }) => {
                if (target.value?.length > 0) setDisable(false);
                setEmail(target.value);
                localStorage.setItem("email", target.value);
              }}
              style={{ width: 290 }}
            />

            <Button
              disabled={isDisabled}
              link={true}
              isLoading={loading}
              style={{ marginTop: 20 }}
              onClick={(e) => {
                e.preventDefault();
                onSubmit();
              }}
            >
              Forgot Password
            </Button>
          </Form>

          <Text size={16} color={"#1C1C1E"} style={{ marginTop: 40 }}>
            New to BezyBee?
            <Text
              color={"rgba(178, 47, 47, 1)"}
              link={true}
              size={16}
              weight={600}
              onClick={() => navigate("/signup")}
            >
              &nbsp;Sign Up
            </Text>
          </Text>
        </div>
      </IAMFormContainner>
    </IAMContainer>
  );
};

export default ForgotPassword;
