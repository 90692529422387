import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import SignIn from "../pages/SignIn";
import SignUp from "../pages/SignUp";
import ForgotPassword from "../pages/ForgotPassword";
import UpdatePassword from "../pages/UpdatePassword";
import SignupVerification from "../pages/SignupVerification";
import Header from "../components/Header";
import Dashboard from "../pages/Dashboard";
import Sidebar from "../components/Sidebar";

const router = [
  {
    path: "/",
    element: <Dashboard />,
  },
  {
    path: "/signin",
    element: <SignIn />,
  },
  {
    path: "/signup",
    element: <SignUp />,
  },
  {
    path: "/signup/verify",
    element: <SignupVerification />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "/update-password",
    element: <UpdatePassword />,
  },
];

const RouterConfig = () => {
  return (
    <Router>
      <div style={{ width: "100%", display: "flex" }}>
        {localStorage.getItem("accessToken") && <Sidebar />}
        <Header />
      </div>
      <Routes>
        {router?.map((route) => (
          <Route exact path={route?.path} element={route?.element} />
        ))}
      </Routes>
    </Router>
  );
};
export default RouterConfig;
