import styled from "styled-components";

export const StyledSearchBar = styled.div`
  margin: ${props => props.mobileViewHeader ? "0px 0px 0px 17px" : "0px"};
  display: flex;
  align-items: center;
  overflow: none;
  border: 0.5px solid #1C1C1E;
  border-radius: 4px;
  height: 45px;
  width: ${props => props.mobileViewHeader ? "80%" : ""};
`;
export const Separator = styled.div`
  height: 30px;
  border: 0.5px solid #ced4da;
  margin: 15px;
`;

export const StyledIcon = styled.div`
  display: flex;
  margin: 0 15px;
  height: 25px;
`;
export const StyledInput = styled.input`
  margin-left: 1%;
  height: 4vh;
  width: 22vw;
  outline: none;
  background-color: transparent;
  border: none;
  ::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 2px;
    height: 100%;
    background-color: #000;
  }

  &:focus {
    outline: none;
  }
`;
