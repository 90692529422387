import { styled } from "styled-components";

const Error = ({ children }) => {
  const ErroMessage = styled.div`
    color: #B22F2F;
    text-align: center;
    font-family: Outfit;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    margin: auto;
    text-align: center;
    display: flex;
    justify-content: center;
    line-height: 150%; /* 22.5px */
    letter-spacing: -0.15px;
    padding-top: 15px;
    padding-bottom: 15px;
  `;
  return <ErroMessage>{children}</ErroMessage>;
};

export default Error;
