import { Logo } from "./index.styled";
import LogoImg from "../../../assets/logo.png";

const IAMSideImage= () => {
  return (
    <Logo src={LogoImg} alt="" />
  );
}

export default IAMSideImage;
