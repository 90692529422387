import { List } from "./index.styled";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { IsUserSignedIn } from "../../../../pages/SignIn/atom";

const DropDown = () => {
  const navigate = useNavigate();
  const setUserSignedIn = useSetRecoilState(IsUserSignedIn);

  const onLogout = async () => {
    setTimeout(() => {
      setUserSignedIn(false);
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("idToken");
      navigate("/signin");
    }, 100);
  };
  return (
    <List>
      <li>Profile</li>
      <li onClick={onLogout}>Logout</li>
      <li>Settings</li>
      {/* {mobileViewHeader && <li>Bookmarks</li>}
      {mobileViewHeader && <li>Messages</li>}
      {mobileViewHeader && <li>Notifications</li>} */}
    </List>
  );
};

export default DropDown;
