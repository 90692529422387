import { StyledButton } from "./index.styled";
import ClipLoader from "react-spinners/ClipLoader";

const Button = ({ primary = true, isLoading, children, ...rest }) => {
  return (
    <StyledButton primary={primary} {...rest}>
      {isLoading ? (
        <ClipLoader
          color={"#fff"}
          loading={isLoading}
          size={30}
          // style={{ display: "flex", margin: "auto" }}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      ) : (
        <>{ children }</>
      )}
    </StyledButton>
  );
};

export default Button;
