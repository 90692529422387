import { useNavigate, Navigate } from "react-router-dom";
import Button from "../../components/Button";

const Dashboard = () => {
  const navigate = useNavigate();

  if (!localStorage.getItem("accessToken"))
    return <Navigate to="/signin" replace />;

  return (
    <div
      style={{
        backgroundColor: "#FAFAFA",
      }}
    ></div>
  );
};

export default Dashboard;
