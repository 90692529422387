import { Container, BaseImage } from "./index.styled";

const IAMLogo = ({ src = "", ...rest }) => {
  return (
    <Container>
      <BaseImage src={src} alt="" />
    </Container>
  );
};

export default IAMLogo;
