import {
  NavbarContainer,
  StyledNavbar,
  Bee,
  LeftSideHeader,
  Logo,
  Menu,
  MenuTitle,
  SubMenu,
  SubMenuItem,
  Navbar,
  NavMenu
} from "./index.styled.js";

import SvgLoader from "../SvgLoader";
import { css } from "@emotion/css";
import {
  IsChildNavbarOpen,
  IsMenuOpen,
  ShowDownHeader,
} from "../../components/Header/atom.js";
import { IsClicked } from "./atom.js";
import { useRecoilValue, useRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import LogoImg from "../../assets/bezybee.png";
import BeeLogo from "../../assets/logo.png";
import { SvgIcon } from "@progress/kendo-react-common";
import { Avatar } from "@progress/kendo-react-layout";
import { userIcon } from "@progress/kendo-svg-icons";
import { MenuItems } from "../../constants/menuItems.js";
import { useState } from "react";

const Sidebar = () => {
  const navigate = useNavigate();
  const [selected, setSelected] = useState(0);
  const isMenuOpen = useRecoilValue(IsMenuOpen);
  const [isSideMenuOpen, setIsSideMenuOpen] = useRecoilState(IsChildNavbarOpen);
  const [isClicked, setClickedItem] = useRecoilState(IsClicked);
  const showDownHeader = useRecoilValue(ShowDownHeader);

  return (
    <NavbarContainer>
      <StyledNavbar>
        <LeftSideHeader>
          <Logo src={LogoImg} alt="" />
          <Bee src={BeeLogo} alt="" />
        </LeftSideHeader>
        <div
          style={{
            position: "relative",
            margin: "30px auto",
          }}
        >
          <Avatar
            rounded="full"
            type="icon"
            className={css`
              padding: 40px;
              background-color: rgba(178, 47, 47, 1);
              border-color: rgba(178, 47, 47, 1);
            `}
          >
            <SvgIcon icon={userIcon} />
          </Avatar>
        </div>
        <Navbar>
          {MenuItems.map((menu, index) => (
            <NavMenu>
              <Menu onClick={() => setSelected(index)} selected={selected === index}>
                <div style={{ display: "flex", gap: 20 }}>
                  <SvgLoader
                    iconName={menu.icon}
                    iconFillColor={selected === index ? "#B22F2F" : "#1C1C1E"}
                  />
                  <MenuTitle>{menu.title}</MenuTitle>
                </div>
                {menu.subMenu?.length > 0  && <SvgLoader
                  iconName={"DownArrow"}
                  iconFillColor={selected === index ? "#B22F2F" : "#1C1C1E"}
                  style={{ transform: selected === index ? "rotate(180deg)" : ""}}
                />}
              </Menu>
              {menu.subMenu?.length > 0 && selected === index && (
                <SubMenu>
                  {menu.subMenu?.map((subMenu) => (
                    <SubMenuItem>{subMenu}</SubMenuItem>
                  ))}
                </SubMenu>
              )}
            </NavMenu>
          ))}
        </Navbar>
      </StyledNavbar>
    </NavbarContainer>
  );
};
export default Sidebar;
