import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  overflow-y: hidden !important;
  align-items: center;
  position: relative;
  border-radius: 24px;
  background-color: #fff;
  border-radius: 24px;
  overflow-x: hidden;

  @media (max-width: 1024px) {
    width: 94%;
    height: max-content;
  }

  @media (max-width: 640px) {
    width: 100%;
  }
`;
