import styled from "styled-components";

export const StyledHeader = styled.header`
  position: fixed;
  display: flex;
  justify-content: space-between;
  // flex-direction: ${(props) => (props.showDownHeader ? "column" : "row")}
  align-items: center;
  top: 0;
  width: ${localStorage.getItem("accessToken") ? "69%" : "100%"};
  position:  ${localStorage.getItem("accessToken") ? "absolute" :""};
  right:  ${localStorage.getItem("accessToken") ? "0px" : ""};
  z-index: 999;
`;

export const LeftSideHeader = styled.div`
  display: flex;
  align-items: center;
  width: 76%;
  z-index: 999;
  height: 44px;
  margin: 4px 20px;
  position: relative;
  height: 128px;
  position: relative;
  @media (max-width: 700px) {
    /* margin: 30px 5px; */
    display: none;
  }
`;

export const Logo = styled.img`
  padding-left: 50px;
  height: 35px;
  width: 220px;
  position: absolute;
  @media (max-width: 991px) {
    width: 140px;
    padding-left: 5px;
    left: 40px;
  }
`;

export const Bee = styled.img`
  position: absolute;
  height: 50px;
  top: 0px;
  left: 265px;
  @media (max-width: 991px) {
    left: 260px;
  }
`;
export const SidebarBtn = styled.div`
  align-items: center;
  background-color: rgba(126, 55, 216, 0.03);
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  margin-bottom: 0;
  margin-top: 6px;
  margin-left: 30px;
  width: 40px;
  @media (max-width: 700px) {
    width: 30px;
    margin-left: 5px;
    height: 30px;
  }
`;

export const RightCol = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px 10px;
  background: #fff;
  position: ${(props) => (props.isHeaderOpen ? "" : "absolute")};
  top: ${(props) => (props.showDownHeader ? "95px" : "20px")};
  left: ${(props) => (props.isHeaderOpen ? "500px" : "0px")};
  z-index: 999;
  visibility: ${(props) =>
    props.showDownHeader || props.isHeaderOpen ? "visible" : "hidden"};
  transition: all 0.3s ease-in-out;
  transform: ${(props) =>
    props.showDownHeader
      ? "translateY(0) scaleY(1)"
      : "translateY(100%) scaleY(0)"};
  animation: ${(props) =>
    props.showDownHeader
      ? "foldDown 1000ms ease-in-out"
      : "foldUp 1000ms ease-in-out"};
  animation-fill-mode: forwards;

  @keyframes foldDown {
    0% {
      transform: translateY(-100%) scaleY(0);
      opacity: 0;
    }
    60% {
      transform: translateY(0) scaleY(1.1);
      opacity: 1;
    }
    100% {
      transform: translateY(0) scaleY(1);
      opacity: 1;
    }
  }
  @keyframes foldUp {
    0% {
      transform: translateY(-100%) scaleY(0.1) rotateX(90deg);
      opacity: 0;
    }
    40% {
      transform: translateY(20px) scaleY(0.95) rotateX(5deg);
    }
    100% {
      transform: translateY(0) scaleY(1) rotateX(0);
      opacity: 1;
    }
  }
`;

export const StyledIcon = styled.li`
  padding: 0px 10px;
  list-style: none;
`;

export const User = styled.div`
  height: 44px;
  border: 1px solid rgba(178, 47, 47, 1);
  border-radius: 35px;
  width: max-content;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 8px;
  padding: 5px 8px;
  position: relative;
  cursor: pointer;
`;
