import { atom } from "recoil";

export const IsMenuOpen = atom({
  key: "IsMenuOpen", // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
});

export const IsChildNavbarOpen = atom({
  key: "IsChildNavbarOpen", // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
});

export const IsHeaderOpen = atom({
  key: "IsHeaderOpen", // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
});

export const ShowDownHeader = atom({
  key: "ShowDownHeader", // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
});

export const ShowUserDropDown = atom({
  key: "ShowUserDropDown", // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
});

export const MobileViewHeader = atom({
  key: "MobileViewHeader",
  default: false,
});
