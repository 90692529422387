export const MenuItems = [
  {
    icon: "Dashboard",
    title: "Dashboard",
    subMenu: null
  },
  {
    icon: "Jobs",
    title: "Job Posting",
    subMenu: [
      "My Jobs",
      "PostNew JOb",
      "My Drafts",
      "Job Templates"
  ]
  },
  {
    icon: "Candidates",
    title: "Candidates",
    subMenu: ["Candidate Applications"]
  },
  {
    icon: "Settings",
    title: "Settings",
    subMenu: null
  },
]