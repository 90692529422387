import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import RouterConfig from "./router";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  IsMenuOpen,
  IsHeaderOpen,
  ShowDownHeader,
  MobileViewHeader,
} from "./components/Header/atom";
import { useLayoutEffect } from "react";
// import { useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import axios from "axios";
// import { IAM_BASE_URL } from "./config/api";


const client = new ApolloClient({
  uri: "",
  cache: new InMemoryCache(),
});

const App = () => {
  const [isMenuOpen, setIsMenuOpen] = useRecoilState(IsMenuOpen);
  const setIsHeaderOpen = useSetRecoilState(IsHeaderOpen);
  const setShowDownHeader = useSetRecoilState(ShowDownHeader);
  const setMobileViewHeader = useSetRecoilState(MobileViewHeader);
  // const navigate = useNavigate();
  // let idleLogout = 1000 * 60 * 30; //2 Minutes
  // let idleEvent;
  // let idleLogoutEvent;

  useLayoutEffect(() => {
    function onChangeScreenSize() {
      if (window.innerWidth <= 700) {
        setIsMenuOpen(false);
        setMobileViewHeader(true);
        setIsHeaderOpen(false);
      } else {
        setIsMenuOpen(true);
        setIsHeaderOpen(true);
        setShowDownHeader(false);
        setMobileViewHeader(false);
      }
    }
    window.addEventListener("resize", onChangeScreenSize);
    onChangeScreenSize();
    return () => window.removeEventListener("resize", onChangeScreenSize);
  }, []);

  /**
   * Add any other events listeners here
   */
  // const events = [
  //   "load",
  //   "mousemove",
  //   "mousedown",
  //   "click",
  //   "scroll",
  //   "keypress",
  // ];

  // const sessionTimeout = () => {
  //   if (!!idleEvent) clearTimeout(idleEvent);
  //   if (!!idleLogoutEvent) clearTimeout(idleLogoutEvent);
  //   idleLogoutEvent = setTimeout(() => logOut, idleLogout); //Call logged out on session expire.
  // };

  // const extendSession = () => {
  //   axios
  //     .post(`${IAM_BASE_URL}/refresh-token`, {
  //       refresh_token: localStorage.getItem("refresh_token"),
  //     })
  //     .then((response) => {
  //       localStorage.setItem("refresh_token", response?.data?.regresh_token);
  //     });
  // };

  // const logOut = () => {
  //   localStorage.clear();
  //   navigate("/sigin");
  // };

  // useEffect(() => {
  //   for (let e in events) {
  //     window.addEventListener(events[e], extendSession);
  //   }
  //   sessionTimeout();
  //   return () => {
  //     for (let e in events) {
  //       window.removeEventListener(events[e], sessionTimeout);
  //     }
  //   };
  // }, []);

  return (
    <ApolloProvider client={client}>
      <RouterConfig />
    </ApolloProvider>
  );
};

export default App;
