import styled from "styled-components";
import Image from "../../Image"

export const Container = styled.div`
  display: flex;
  margin: 0px !important;
  padding: 0 !important;
  width: 50%;

  @media (max-width: 1024px) {
    display: none;
  }
`;

  export const BaseImage = styled(Image)`
  position: relative;
  object-fit: contain;
`;

