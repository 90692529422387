import IAMContainer from "../../components/IAM/IAMContainer";
import IAMFormContainner from "../../components/IAM/IAMFormContainer";
import IAMImageContainer from "../../components/IAM/IAMImageContainer";
import SignupImg from "../../assets/signin.png";
import InputField from "../../components/Input";
import Form from "../../components/Form";
import FormHeading from "../../components/FormHeading";
import Button from "../../components/Button";
import IAMLogo from "../../components/IAM/IAMLogo";
import { IAM_BASE_URL } from "../../config/api";
import axios from "axios";
import Text from "../../components/Text";
import { useEffect, useState } from "react";
import { useNavigate, Navigate } from "react-router-dom";
import CodeIcon from "../../assets/code.png";
import PasswordIcon from "../../assets/lock.png";
import Error from "../../components/Error";

const SignupVerification = () => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [isDisable, setDisable] = useState(true);
  const [values, setValues] = useState({
    code: "",
    password: "",
    confirmPassword: "",
  });

  const checkForFormEnable = () => {
    if (
      values?.code?.length === 6 &&
      !values.code?.includes(" ") &&
      values.password &&
      values?.confirmPassword &&
      values.password === values?.confirmPassword
    ) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  };

  useEffect(() => {
    checkForFormEnable();
  }, [values]);

  const onReset = async () => {
    setLoading(true);
    await axios
      .post(`${IAM_BASE_URL}/forgot-password-confirmation`, {
        email: localStorage.getItem("email"),
        password: values.password,
        verification_code: values.code,
      })
      .then((response) => {
        setLoading(false);
        if (response?.data?.success) {
          navigate("/signin");
        }
      })
      .catch((e) => {
        setLoading(false);
        setDisable(true);
        setError(e?.response?.data?.message);
      });
  };

  const onResend = async () => {
    setLoading(true);
    await axios
      .post(`${IAM_BASE_URL}/forgot-password`, {
        email: localStorage.getItem("email"),
      })
      .then((resp) => {
        setLoading(false);
        if (resp?.data?.success) {
          setDisable(true);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError(err?.response?.data?.message);
        console.log("error", err);
      });
  };

  if (localStorage.getItem("accessToken")) return <Navigate to="/" replace />;

  return (
    <IAMContainer statement={"Let’s get you back on the system"}>
      <IAMImageContainer src={SignupImg} />
      <IAMFormContainner>
        <IAMLogo />
        <div
          style={{
            flex: 1,
            margin: "auto",
          }}
        >
          <FormHeading>Update Password</FormHeading>
          <Text style={{ width: 290, marginBottom: 30 }} alignment={"left"}>
            Please review the email we just sent you and provide the security
            code received in the email.
          </Text>

          <Form>
            <Error>{error}</Error>
            <InputField
              id={"code"}
              name={"code"}
              placeholder={"code"}
              icon={CodeIcon}
              type={"number"}
              validationMessage={"Please enter a valid email"}
              value={values.code}
              maxLength={6}
              onChange={({ target}) => {
                setValues({ ...values, code: target.value });
              }}
              style={{ width: 290, letterSpacing: "0.2em", fontWeight: 700 }}
            />

            <InputField
              id={"password"}
              name={"password"}
              placeholder={"new password"}
              icon={PasswordIcon}
              type={"password"}
              validator={() =>
                values.password?.length >= 8
                  ? ""
                  : "Password must be at least 8 characters long, have at least one uppercase letter, one lowercase letter, one number and one special character."
              }
              validationMessage={
                "Password must be at least 8 characters long, have at least one uppercase letter, one lowercase letter, one number and one special character."
              }
              value={values.password}
              onChange={({ target }) =>
                setValues({ ...values, password: target.value })
              }
              style={{ width: 290 }}
            />

            <InputField
              id={"confirmPassword"}
              name={"confirmPassword"}
              placeholder={"confirm password"}
              type={"password"}
              icon={PasswordIcon}
              value={values.confirmPassword}
              validator={() =>
                values.confirmPassword?.length >= 8 &&
                values.password === values.confirmPassword
                  ? ""
                  : "Password must be at least 8 characters long, have at least one uppercase letter, one lowercase letter, one number and one special character."
              }
              validationMessage={
                "Password must be at least 8 characters long, have at least one uppercase letter, one lowercase letter, one number and one special character."
              }
              onChange={({ target }) =>
                setValues({ ...values, confirmPassword: target.value })
              }
              style={{ width: 290 }}
            />

            <Button
              style={{ marginTop: 20 }}
              isLoading={loading}
              onClick={(e) => {
                e.preventDefault();
                onReset();
              }}
              disabled={isDisable}
            >
              Reset Password
            </Button>
          </Form>
          <Text
            color={"#1C1C1E"}
            style={{ marginTop: 40, marginBottom: 40 }}
            size={16}
          >
            Didn’t receive the email?{" "}
            <Text
              color={"rgba(178, 47, 47, 1)"}
              link={true}
              size={16}
              weight={600}
              onClick={() => onResend()}
            >
              &nbsp;Resend
            </Text>
          </Text>
        </div>
      </IAMFormContainner>
    </IAMContainer>
  );
};

export default SignupVerification;
