import { Button } from "@progress/kendo-react-buttons";
import styled from "styled-components";

export const StyledButton = styled(Button)`
  width: 100%;
  margin: auto;
  display: flex;
  max-width: 400px;
  background-color: ${(props) =>
    props.primary ? "rgba(178, 47, 47, 1)" : "#fff"};
  color: ${(props) => (props.primary ? "#fff" : "rgba(178, 47, 47, 1)")};
  border: rgba(178, 47, 47, 1);
  border-radius: 8px;
  height: 45px;
  &:hover {
    background-color: ${(props) =>
      props.primary ? "rgba(178, 47, 47, 0.9)" : "#fff"};
  }
  font-family: Outfit;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0.02em;
  text-align: left;

  span {
    display: flex;
    margin: auto;
  }
  @media (max-widrh: 340px) {
    width: 97%;
  }
`;
