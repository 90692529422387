import IAMContainer from "../../components/IAM/IAMContainer";
import IAMFormContainner from "../../components/IAM/IAMFormContainer";
import IAMImageContainer from "../../components/IAM/IAMImageContainer";
import InputField from "../../components/Input";
import SignupImg from "../../assets/signup.png";
import Form from "../../components/Form";
import FormHeading from "../../components/FormHeading";
import Button from "../../components/Button";
import IAMLogo from "../../components/IAM/IAMLogo";
import { IAM_BASE_URL } from "../../config/api";
import axios from "axios";
import CodeIcon from "../../assets/code.png";
import Text from "../../components/Text";
import { useEffect, useState } from "react";
import { useNavigate, Navigate } from "react-router-dom";
import Error from "../../components/Error";

const SignupVerification = () => {
  const navigate = useNavigate();
  const [isDisable, setDisable] = useState(true);
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (code?.length === 6) setDisable(false);
    else setDisable(true);
  }, [code]);

  const onReset = async () => {
    setLoading(true);

    await axios
      .post(`${IAM_BASE_URL}/confirm`, {
        email: localStorage.getItem("email"),
        verification_code: code,
      })
      .then((response) => {
        if (response?.data?.success) {
          setLoading(false);
          navigate("/");
        }
      })
      .catch((e) => {
        setLoading(false);
        setDisable(true);
        setError(e?.response?.data?.message);
      });
  };

  const onResendVerification = async () => {
    setLoading(true);
    axios
      .post(`${IAM_BASE_URL}/resend-confirmation-code`, {
        email: localStorage.getItem("email"),
      })
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(err?.response?.data?.message);
      });
  };

  if (localStorage.getItem("accessToken")) return <Navigate to="/" replace />;

  return (
    <IAMContainer statement={"Let’s get started with Bezy Bee"}>
      <IAMImageContainer src={SignupImg} />
      <IAMFormContainner>
        <IAMLogo />
        <div
          style={{
            flex: 1,
            margin: "auto",
          }}
        >
          <FormHeading>Verify Code</FormHeading>
          <Text style={{ width: 290, marginBottom: 30 }} alignment={"left"}>
            Please enter your 6 digits verification code from your registered
            email that we’ve sent.
          </Text>
          <br />
          <Form>
            <Error>{error}</Error>
            <InputField
              id={"code"}
              name={"code"}
              type={"number"}
              placeholder={"code"}
              icon={CodeIcon}
              validationMessage={"Please enter a valid email"}
              value={code}
              onChange={(e) => {
                console.log(e);
                if (e.target.value?.length === 6) setDisable(false);
                else setDisable(true);
                if (e.target.value?.length <= 6) setCode(e.target.value);
              }}
              style={{ width: 290, letterSpacing: "0.2em", fontWeight: 700 }}
            />

            <Button
              style={{ marginTop: 20 }}
              isLoading={loading}
              onClick={onReset}
              disabled={isDisable}
            >
              Verify
            </Button>
          </Form>
          <Text size={16} color={"#1C1C1E"} style={{ marginTop: 40 }}>
            Didn't receive the email?{" "}
            <Text
              color={"rgba(178, 47, 47, 1)"}
              link={true}
              size={16}
              weight={600}
              onClick={onResendVerification}
            >
              &nbsp;Resend
            </Text>
          </Text>
        </div>
      </IAMFormContainner>
    </IAMContainer>
  );
};

export default SignupVerification;
