import Image from "../../Image/index";
import SearchBar from "../../SearchBar/index";
import { css } from "@emotion/css";
import { RightCol, User } from "../index.styled";
import { StyledIcon } from "../index.styled";
import notificationsIcon from "../../../assets/notification.png";
import inboxIcon from "../../../assets/message-icon.png";
import { useRecoilState, useRecoilValue } from "recoil";
import DownArrow from "../../../assets/down-arrow.svg";
import { MobileViewHeader, ShowDownHeader, ShowUserDropDown } from "../atom";
import Text from "../../Text";
import { Avatar } from "@progress/kendo-react-layout";
import { SvgIcon } from "@progress/kendo-react-common";
import { userIcon } from "@progress/kendo-svg-icons";
import DropDown from "./DropDown";

const RightSideHeader = ({ isHeaderOpen }) => {
  const showDownHeader = useRecoilValue(ShowDownHeader);
  const [showUserDropDown, setShowUserDropDown] =
    useRecoilState(ShowUserDropDown);
  const mobileViewHeader = useRecoilValue(MobileViewHeader);

  return (
    <RightCol showDownHeader={showDownHeader} isHeaderOpen={isHeaderOpen}>
      <SearchBar />

      <div style={{ display: "flex", alignItems: "center", marginRight: 40 }}>
        {!mobileViewHeader && (
          <>

            <StyledIcon>
              <Image src={inboxIcon} alt="search_icon" height={30} />
            </StyledIcon>

            <StyledIcon>
              <Image src={notificationsIcon} alt="search_icon" height={30} />
            </StyledIcon>
          </>
        )}
        <User onClick={() => setShowUserDropDown(!showUserDropDown)}>
          <Avatar
            rounded="full"
            type="icon"
            className={css`
              padding: 20px;
              background-color: rgba(178, 47, 47, 1);
              border-color: rgba(178, 47, 47, 1);
            `}
          >
            <SvgIcon icon={userIcon} />
          </Avatar>
          <Text size={16}>Abdul Qadir</Text>
          <img src={DownArrow} hieght={30} width={30} alt="" />
          {showUserDropDown && <DropDown />}
        </User>
      </div>
    </RightCol>
  );
};

export default RightSideHeader;
