import { useRecoilValue } from "recoil";
import searchIcon from "../../assets/search-icon.svg";

import Image from "../Image/index";
import {
  StyledIcon,
  StyledInput,
  StyledSearchBar,
} from "./index.styled";
import { MobileViewHeader } from "../../components/Header/atom";

const SearchBar = () => {
  const mobileViewHeader = useRecoilValue(MobileViewHeader);
  return (
    <StyledSearchBar mobileViewHeader={mobileViewHeader}>
      <StyledIcon>
        <Image src={searchIcon} alt="search_icon" />
      </StyledIcon>
      <StyledInput type="text" placeholder="search job title or skill" />
    </StyledSearchBar>
  );
};

export default SearchBar;
