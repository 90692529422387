export const TermsAndConditions = [
  {
    heading: "1. Acceptance of Terms",
    detail: "By using this website, you agree to be bound by these terms and conditions, our Privacy Policy, and any additional guidelines and rules posted on this website. , you agree to be bound by these terms and conditions, our Privacy Policy, and any additional guidelines and rules posted on this website. If you do not agree with these terms, please refrain from using our website."
  },
  {
    heading: "2. Intellectual Property Rights",
    detail: "All content on this website, including text, graphics, logos, images, and software, is the property of BezyBww and is protected by copyright and other intellectual property laws. You may not reproduce, distribute, or use any of the content without our express written permission."
  },
  {
    heading: "3. User Conduct",
    detail: "You agree to use this website in a manner consistent with all applicable laws and regulations. You will not transmit any unlawful, harmful, or offensive content. You will not attempt to gain unauthorized access to our website or other users' accounts. You will not use this website in a way that may disrupt or harm its functionality."
  },
  {
    heading: "4. User Registration",
    detail: "Certain parts of this website may require you to register an account. You are responsible for maintaining the confidentiality of your account information and for any activities that occur under your account. BezyBee reserves the right to terminate accounts for any violation of these terms."
  },
  {
    heading: "5. Privacy",
    detail: "Your use of this website is also governed by our Privacy Policy. By using our website, you consent to the collection and use of your information as described in the Privacy Policy."
  },
  {
    heading: "6. Disclaimer",
    detail: `This website and its content are provided "as is" without any warranties of any kind, either express or implied. BezyBee makes no representations or warranties about the accuracy, reliability, or availability of the website.`
  },
  {
    heading: "7. Limitation of Liability",
    detail: "Your Company] shall not be liable for any direct, indirect, incidental, consequential, or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data, or other intangible losses resulting from the use or inability to use this website."
  },
  {
    heading: "8. Governing Law and Jurisdiction",
    detail: "These terms and conditions are governed by and construed in accordance with the laws of [Your Jurisdiction]. You agree to submit to the exclusive jurisdiction of the courts located in [Your Jurisdiction] for any dispute arising from or related to these terms and conditions"
  },
]