import styled from "styled-components";
import Bg from "../../../assets/BG.png";

export const Wrapper = styled.div`
  padding-top: 55px;
  margin: 0px;
  width: 100%;
  min-height: 800px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${Bg});
  position: relative;
`;

export const Container = styled.div`
  height: 80%;
  display: flex;
  flex-direction: row;
  width: 80%;
  margin: 50px auto 0px !important;
  justify-content: center;

  @media (max-width: 380px) {
    width: 90%;
    margin: 0px auto !important;
  }

  @media (max-width: 300px) {
    width: 100%;
  }
`;

export const Footer = styled.div`
  border-radius: 24px;
  background-color: #fff;
  box-shadow: 4px 16px 32px 0px rgba(0, 0, 0, 0.04);
  position: absolute;
  padding: 20px;
  margin: auto !important;
  margin-top: 20px !important;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 70%;
  display: flex;
  justify-content: center;
  text-align: center;
  color: #1c1c1e;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px; /* 120% */

  @media (max-width: 915px) {
    font-size: 34px;
    font-weight: 600;
  }

  @media (max-width: 640px) {
    margin-top: 60px;
    font-size: 24px;
  }

  @media (max-width: 340px) {
    width: 100%;
  }
`;
