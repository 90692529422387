import IAMContainer from "../../components/IAM/IAMContainer";
import IAMFormContainner from "../../components/IAM/IAMFormContainer";
import IAMImageContainer from "../../components/IAM/IAMImageContainer";
import EmailIcon from "../../assets/email.png";
import PasswordIcon from "../../assets/lock.png";
import SignupImg from "../../assets/signup.png";
import InputField from "../../components/Input";
import Form from "../../components/Form";
import FormHeading from "../../components/FormHeading";
import Button from "../../components/Button";
import CheckboxInput from "../../components/Checkbox";
import IAMLogo from "../../components/IAM/IAMLogo";
import { IAM_BASE_URL, ORG_ROLE_ID } from "../../config/api";
import axios from "axios";
import Text from "../../components/Text";
import { useEffect, useState } from "react";
import { useNavigate, Navigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { SignupStates } from "./atom";
import Modal from "../../components/Modal";
import { css, cx } from "@emotion/css";
import Error from "../../components/Error";
import { TermsAndConditions } from "../../constants/termsAndConditions";

const SignUp = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const [isDisable, setDisable] = useState(true);
  const [values, setValues] = useRecoilState(SignupStates);

  const validatePassword = () => {
    const passwordRegex = new RegExp(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
    );
    return passwordRegex.test(values.password);
  };

  const checkForFormEnable = () => {
    if (
      values.termAndConditionsAcceptance === true &&
      values.password === values.confirmPassword
    ) {
      console.log("vakjl", values);
      setDisable(false);
    } else {
      setDisable(true);
    }
  };

  useEffect(() => {
    checkForFormEnable();
  }, [values]);

  const onSignup = async () => {
    setLoading(true);
    await axios
      .post(`${IAM_BASE_URL}/signup`, {
        email: values.email,
        password: values.password,
        role_id: ORG_ROLE_ID,
        terms_and_conditions: values.termAndConditionsAcceptance,
        signup_for_electronic_communication: true,
      })
      .then((response) => {
        localStorage.setItem("email", values.email);
        const { user_id } = response?.data;
        localStorage.setItem("user_id", user_id);
        setLoading(false);
        navigate("/signup/verify");
      })
      .catch((err) => {
        setLoading(false);
        setError(err?.response?.data?.message);
        console.log("Error on signing up", err);
      });
  };

  if (localStorage.getItem("accessToken")) return <Navigate to="/" replace />;

  return (
    <>
      <IAMContainer statement={"Let’s get started with Bezy Bee"}>
        <IAMImageContainer src={SignupImg} />
        <IAMFormContainner>
          <IAMLogo />
          <div
            style={{
              flex: 1,
              margin: "auto",
              paddingBottom: 20,
              maxWidth: 290,
            }}
            className={cx(
              "",
              css`
                @media (max-width: 340px) {
                  width: 100% !important;
                }
              `
            )}
          >
            <FormHeading>Sign Up</FormHeading>
            <Form>
              <Error>{error}</Error>
              <InputField
                id={"email"}
                name={"email"}
                placeholder={"email address"}
                type={"email"}
                validationMessage={"Please enter a valid email"}
                value={values.email}
                icon={EmailIcon}
                onChange={({ target }) =>
                  setValues({ ...values, email: target.value })
                }
              />

              <InputField
                id={"password"}
                name={"password"}
                placeholder={"password"}
                type={"password"}
                icon={PasswordIcon}
                defaultValue={values.password}
                autocomplete="off"
                validator={() =>
                  validatePassword()
                    ? ""
                    : "Password must be at least 8 characters long, have at least one uppercase letter, one lowercase letter, one number and one special character."
                }
                validationMessage={
                  "Password must be at least 8 characters long, have at least one uppercase letter, one lowercase letter, one number and one special character."
                }
                value={values.password}
                onChange={({ target }) =>
                  setValues({ ...values, password: target.value })
                }
              />

              <InputField
                id={"confirmPassword"}
                name={"confirmPassword"}
                placeholder={"confirm password"}
                type={"password"}
                defaultValue={values.confirmPassword}
                autocomplete="off"
                value={values.confirmPassword}
                icon={PasswordIcon}
                validator={() =>
                  validatePassword()
                    ? ""
                    : "Password must be at least 8 characters long, have at least one uppercase letter, one lowercase letter, one number and one special character."
                }
                validationMessage={
                  "Password must be at least 8 characters long, have at least one uppercase letter, one lowercase letter, one number and one special character."
                }
                onChange={({ target }) =>
                  setValues({ ...values, confirmPassword: target.value })
                }
              />

              <br />
              <br />
              <CheckboxInput
                checked={values.termAndConditionsAcceptance}
                onClick={() => setShowModal(true)}
                label={"I have reviewed and agree with the terms & conditions."}
              />

              <Button
                style={{ marginTop: 20, width: 290 }}
                isLoading={loading}
                onClick={(e) => {
                  e.preventDefault();
                  onSignup();
                }}
                disabled={isDisable}
              >
                Sign up
              </Button>
            </Form>
            <Text size={16} color={"#1C1C1E"} style={{ marginTop: 40 }}>
              Already have an account?{" "}
              <Text
                color={"rgba(178, 47, 47, 1)"}
                link={true}
                size={16}
                weight={600}
                onClick={() => navigate("/signin")}
              >
                &nbsp;Sign In
              </Text>
            </Text>
          </div>
        </IAMFormContainner>
      </IAMContainer>

      {showModal && (
        <Modal
          primaryButton={"Accept"}
          secondaryButton={"Decline"}
          header={"Terms & Conditions"}
          onCancel={() => setShowModal(false)}
          onDecline={() => {
            setValues({
              ...values,
              termAndConditionsAcceptance: false,
            });
            setShowModal(false);
          }}
          onSubmit={() => {
            setValues({
              ...values,
              termAndConditionsAcceptance: true,
            });
            setShowModal(false);
          }}
        >
          <div style={{ textAlign: "left", color: "#1C1C1E", lineHeight: "25px", overflowY: "auto",
    height: "56vh" }}>
            <p style={{ fontSize: 18 }}>
              Terms and Conditions for{" "}
              <span style={{ fontWeight: 700, fontSize: 22 }}>BezyBee.com</span>
            </p>
            {TermsAndConditions?.map((term) => (
              <>
                <p style={{ fontWeight: 700, fontSize: 18,  margin: 0 }}>
                  <span>
                    {term.heading}
                  </span>
                </p>
                <p style={{ fontSize: 16, margin: 0 }}>{term.detail}</p>
              </>
            ))}
          </div>
        </Modal>
      )}
    </>
  );
};

export default SignUp;
