import { atom } from "recoil";

export const SignupStates = atom({
  key: "SignupStates",
  default: {
    email: "",
    password: "",
    confirmPassword: "",
    termsAndConditionsAcceptance: false
  }
})




