import * as React from "react";
import { Field, FieldWrapper } from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import { Label, Error, FloatingLabel } from "@progress/kendo-react-labels";
import { cx, css } from "@emotion/css";

const emailRegex = new RegExp(/\S+@\S+\.\S+/);
const emailValidator = (value) => {
  if (!emailRegex.test(value)) return "Please enter a valid email.";
};

const SimpleInput = (fieldRenderProps) => {
  const {
    validationMessage,
    visited,
    label,
    id,
    valid,
    disabled,
    style,
    icon,
    maxLength,
    ...others
  } = fieldRenderProps;
  const showValidationMessage = visited && validationMessage;
  return (
    <FieldWrapper style={{ marginBottom: 10, width: 290, ...style }} className={cx("", css`
      @media (max-width: 340px) {
        width: 280px !important;
      }
      @media (max-width: 290px) {
        width: 270px !important;
      }
    `)}>
      <Label editorId={id} editorValid={valid} editorDisabled={disabled}>
        {label}
      </Label>
      <Input
        valid={valid}
        id={id}
        maxLength={maxLength}
        disabled={disabled}
        style={{
          ...style,
          height: 50,
          borderRadius: 8,
          border:
            "1px solid var(--light-others-outline-border-line, rgba(28, 28, 30, 0.48))",
          background: `url(${icon}) no-repeat scroll 7px 7px`,
          backgroundPosition: "center left",
          paddingLeft: 40,
        }}
        className={cx(
          "",
          css`
            &::placeholder {
              letter-spacing: normal !important;
              font-weight: normal;
            }
            &:-ms-input-placeholder {
              letter-spacing: normal !important;
              font-weight: normal;
            }
            &::-ms-input-placeholder {
              letter-spacing: normal !important;
              font-weight: normal;
            }
          `
        )}
        {...others}
      />
      {showValidationMessage && <Error>{validationMessage}</Error>}
    </FieldWrapper>
  );
};
const FloatingInput = (fieldRenderProps) => {
  const {
    validationMessage,
    visited,
    label,
    id,
    valid,
    value,
    disabled,
    style,
    placeholder,
    icon,
    ...others
  } = fieldRenderProps;
  const showValidationMessage = visited && validationMessage;
  return (
    <FieldWrapper style={{ margin: 0, ...style }}>
      <FloatingLabel
        label={label}
        editorValue={value}
        editorValid={valid}
        editorDisabled={disabled}
        editorId={id}
        style={{ color: "#49505780" }}
      >
        <Input
          valid={valid}
          type={"email"}
          id={id}
          placeholder={placeholder}
          disabled={disabled}
          style={{
            height: 50,
            borderRadius: 8,
            border:
              "1px solid var(--light-others-outline-border-line, rgba(28, 28, 30, 0.48))",
            background: `url(${icon}) no-repeat scroll 7px 7px`,
            backgroundPosition: "center left",
            paddingLeft: 40,
          }}
          {...others}
        />

        {showValidationMessage && <Error>{validationMessage}</Error>}
      </FloatingLabel>
    </FieldWrapper>
  );
};
const InputField = ({ floating = false, value, ...rest }) => {
  return floating ? (
    <Field
      {...rest}
      component={FloatingInput}
      validator={rest.type === "email" ? emailValidator : rest.validator}
    />
  ) : (
    <Field
      {...rest}
      component={SimpleInput}
      validator={rest.type === "email" ? emailValidator : rest?.validator}
    />
  );
};

export default InputField;
