import IAMContainer from "../../components/IAM/IAMContainer";
import IAMFormContainner from "../../components/IAM/IAMFormContainer";
import IAMImageContainer from "../../components/IAM/IAMImageContainer";
import SigninImg from "../../assets/signin.png";
import InputField from "../../components/Input";
import Form from "../../components/Form";
import Text from "../../components/Text";
import FormHeading from "../../components/FormHeading";
import Button from "../../components/Button";
import IAMLogo from "../../components/IAM/IAMLogo";
import { IAM_BASE_URL } from "../../config/api";
import axios from "axios";
import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { IsUserSignedIn } from "./atom";
import { emailValidator } from "../../utils/helper";
import EmailIcon from "../../assets/email.png";
import PasswordIcon from "../../assets/lock.png";
import CheckboxInput from "../../components/Checkbox";
import Error from "../../components/Error";

const SignIn = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDisabled, setDisabled] = useState(true);
  const [check, setCheck] = useState(
  (localStorage.getItem("stored_email") &&
      localStorage.getItem("stored_password")) ? true : false
  );
  const setUserSignedIn = useSetRecoilState(IsUserSignedIn);
  const [values, setValues] = useState({
    email: localStorage.getItem("stored_email") ?? "",
    password: localStorage.getItem("stored_password") ?? "",
  });
  useEffect(() => {
    if (emailValidator(values.email) && values.password) setDisabled(false);
    else setDisabled(true);
  }, [values]);

  const onSignin = async () => {
    setLoading(true);
    await axios
      .post(`${IAM_BASE_URL}/signin`, {
        ...values,
      })
      .then((response) => {
        setUserSignedIn(true);
        localStorage.setItem(
          "accessToken",
          response?.data?.raw?.AuthenticationResult?.AccessToken
        );
        localStorage.setItem(
          "refreshToken",
          response?.data?.raw?.AuthenticationResult?.RefreshToken
        );
        localStorage.setItem(
          "idToken",
          response?.data?.raw?.AuthenticationResult?.IdToken
        );
        navigate("/");
      })
      .catch((err) => {
        setLoading(false);
        setError(err?.response?.data?.message);
        console.log("Erron on sigin", err);
      });
  };

  if (localStorage.getItem("accessToken")) return <Navigate to="/" replace />;

  return (
    <>
      <IAMContainer statement={"Let’s help you hire the best talent!"}>
        <IAMImageContainer src={SigninImg} />
        <IAMFormContainner>
          <IAMLogo />
          <div style={{ flex: 1, margin: "auto", paddingBottom: 20 }}>
            <FormHeading>Sign in</FormHeading>
            <Form>
              <Error>{error}</Error>
              <InputField
                id={"email"}
                name={"email"}
                placeholder={"email address"}
                type={"email"}
                defaultValue={values.email}
                validationMessage={"Please enter a valid email"}
                value={values.email}
                icon={EmailIcon}
                onChange={({ target }) =>
                  setValues({ ...values, email: target.value })
                }
                style={{ width: 290 }}
              />

              <InputField
                id={"password"}
                name={"password"}
                placeholder={"password"}
                type={"password"}
                defaultValue={values.password}
                icon={PasswordIcon}
                validator={() =>
                  values.password?.length >= 8 ? "" : "This field is required"
                }
                validationMessage={"This field is required"}
                value={values.password}
                onChange={({ target }) =>
                  setValues({ ...values, password: target.value })
                }
                style={{ width: 290 }}
              />

              <Button
                style={{ marginTop: 20 }}
                isLoading={loading}
                disabled={isDisabled}
                onClick={(e) => {
                  e.preventDefault();
                  onSignin();
                }}
              >
                Sign In
              </Button>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  marginTop: 20,
                }}
              >
                <Text size={16}>
                  <CheckboxInput
                    style={{ marginRight: 10 }}
                    checked={check}
                    onClick={() => {
                      setCheck(!check);
                      if (!check && (values.email && values.password)) {
                        localStorage.setItem("stored_email", values.email);
                        localStorage.setItem(
                          "stored_password",
                          values.password
                        );
                      } else {
                        localStorage.clear();
                      }
                    }}
                  />
                  Remember Me?
                </Text>
                <Text
                  color={"#B22F2F"}
                  size={16}
                  weight={600}
                  alignment={"right"}
                  link={true}
                  onClick={() => navigate("/forgot-password")}
                >
                  Forgot Password
                </Text>
              </div>
            </Form>
            <Text size={16} color={"#1C1C1E"} style={{ marginTop: 40 }}>
              New to BezyBee?
              <Text
                color={"rgba(178, 47, 47, 1)"}
                link={true}
                size={16}
                weight={600}
                onClick={() => navigate("/signup")}
              >
                &nbsp;&nbsp;Sign Up
              </Text>
            </Text>
          </div>
        </IAMFormContainner>
      </IAMContainer>
    </>
  );
};

export default SignIn;
