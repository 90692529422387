import Button from "../Button";
import "./index.styles.css";
import Close from "../../assets/cross.png"

export const Modal = ({
  onCancel,
  onDecline,
  onSubmit,
  style,
  header,
  children,
  primaryButton,
  secondaryButton,
}) => {
  return (
    <div id="id01" className="modal" style={{ ...style }}>
      <form className="modal-content" action="">
        <header class="modal-header">
          <h2 style={{ color: "#fff" }}>{header}</h2>
          <span
            onClick={onCancel}
            class="w3-button w3-display-topright"
          >
           <img className="close" src={Close} height={30} width={30} alt="" />
          </span>
        </header>
        <div className="content-container" style={{ margin: 10 }}>{children}</div>

        <div className="footer">
          <Button
            type="button"
            primary={false}
            class="btn btn-light"
            style={{ border: "1px solid", width: 200, margin: "unset" }}
            onClick={onDecline}
          >
            {secondaryButton}
          </Button>
          <Button
            type="button"
            primary={true}
            style={{ width: 200, margin: "unset"  }}
            onClick={onSubmit}
          >
            {primaryButton}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Modal;
