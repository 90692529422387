// import Image from "../Image/index";
import {
  StyledHeader,
  Logo,
  // SidebarBtn,
  LeftSideHeader,
  Bee,
} from "./index.styled";
import LogoImg from "../../assets/bezybee.png";
import BeeLogo from "../../assets/logo.png";
// import mobileSidebarImg from "../../assets/mobileSidebar.svg";
import RightSideHeader from "./Components/rightSideHeader";
import Button from "../Button/index";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  IsHeaderOpen,
  // IsMenuOpen,
  ShowDownHeader,
} from "./atom";

const Header = () => {
  const [showDownHeader, setShowDownHeader] = useRecoilState(ShowDownHeader);
  // const [isMenuOpen, setIsMenuOpen] = useRecoilState(IsMenuOpen);
  const isHeaderOpen = useRecoilValue(IsHeaderOpen);
  const isUserSignedIn = localStorage.getItem("accessToken");

  return (
    <StyledHeader>
      {!isUserSignedIn && (
        <LeftSideHeader>
          <Logo src={LogoImg} alt="" />
          <Bee src={BeeLogo} alt="" />

          {/* <SidebarBtn
          onClick={() => {
            return setIsMenuOpen(!isMenuOpen);
          }}
        >
          <Image src={mobileSidebarImg} alt="" />
        </SidebarBtn> */}
        </LeftSideHeader>
      )}
      {isUserSignedIn && (
        <>
          {!isHeaderOpen && (
            <Button
              style={{ width: "20px", marginRight: 17 }}
              onClick={() => setShowDownHeader(!showDownHeader)}
            ></Button>
          )}
          {isHeaderOpen && <RightSideHeader isHeaderOpen={isHeaderOpen} />}
          {showDownHeader && <RightSideHeader isHeaderOpen={isHeaderOpen} />}
        </>
      )}
    </StyledHeader>
  );
};

export default Header;
