import { Container, Wrapper, Footer } from "./index.styled";

const IAMFormContainer = ({ children, statement }) => {
  return (
    <Wrapper>
      <Container>{children}</Container>
      <Footer>{statement}</Footer>
      </Wrapper>
  );
};

export default IAMFormContainer;
